:root {
  --theme1: #c8850c;
  --theme1Dark: #000;
  --theme: #ffffff;
  --themeSuccess: #11d900;
  --themeDanger: #ff3030;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-Light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-SemiBold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-Medium.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../fonts/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: Medium;
  font-style: normal;
  font-display: swap;
}

html,
body {
  overflow: auto !important;
  overflow-x: hidden !important;
}

body {
  padding: 0;
  background-color: var(--theme1Dark) !important;
  letter-spacing: -0.3px;
  color: var(--theme);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: "Space Grotesk" !important;
}
aside {
  background: #383b42 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 40px 40px 0;
}
.layout-dashboard .ant-layout-sider.sider-primary {
  width: 250px;
  padding: 33px 20px;
  overflow-x: hidden !important;
  height: 100vh;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
  width: auto;
  overflow-x: hidden;
  overflow: auto;
}
.brand-logo {
  padding: 40px 25px;
  position: relative;
  z-index: 11;
  text-align: center;
}
.ant-menu-light.sideNavMain {
  position: relative;
  z-index: 11;
}
.layout-dashboard .ant-menu {
  background: transparent;
  border-right: 0 !important;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
  padding: 0 !important;
  height: auto;
  line-height: normal;
  margin: 10px 0px !important;
  border: 4px solid transparent;
  border-radius: 25px;
}
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin: 0 !important;
}
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item-selected
  .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item-selected
  .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
  font-weight: 600;
  color: var(--theme1Dark) !important;
}
li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child
  .ant-menu-title-content
  img {
  background-color: #000;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  transition: none;
}
li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child
  .ant-menu-title-content
  a:hover
  span {
  color: #000 !important;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active:hover {
  /* background-color: var(--theme1) !important; */
  color: #fff !important;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a,
.sideNavMain .ant-menu-submenu-title {
  padding: 10px 16px !important;
  color: #ffffffb2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-bottom: solid 0 #f1f1f1;
  border-radius: 0 !important;
}
.ant-drawer-content { 
  background: #383b42 !important; 
}
button.ant-btn.ant-btn-link.sidebar-toggler {
  background-color: #353535;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: #fff;
}
button.ant-btn.ant-btn-link.sidebar-toggler svg {
  fill: #fff;
}

.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  border-right: 0px solid #1890ff !important; 
}
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item
  .active
  .ant-image
  img {
  filter: unset !important;
  width: 25px;
}
.layout-dashboard .ant-layout {
  background: transparent;
  position: relative;
  min-height: calc(100vh - 0);
  width: 100%;
  flex-shrink: 0;
  padding-bottom: 20px;
}
.layout-dashboard .ant-layout-header {
  background: var(--theme1Dark);
  height: auto;
  padding: 16px;
  margin: 10px 4px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-dashboard .ant-layout-content {
  padding: 0;
  margin: 0 15px 0;
}
.sectionheadding h2 {
  font-family: "Space Grotesk";
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;

  color: #fff;
  margin: 0;
}
.sectionheadding p {
  font-family: "Space Grotesk";
  font-size: 12px;
  font-weight: 500;
  line-height: 15.31px;
  color: #c5c5c5;
}
.user-profile h6 {
  color: #fff;
  margin: 0;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.42px;
}
.user-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.ant-layout.ant-layout-has-sider {
  background-color: var(--theme1Dark) !important;
}
.brand-logo img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--theme1Dark);
  padding-bottom: 10px;
  padding-top: 20px;
  position: relative;
}
.header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.16) 0%,
    rgba(255, 255, 255, 0.16) 50%,
    rgba(0, 0, 0, 0.16) 100%
  );
}
.join-btn-neww {
  width: 100%;
  max-width: 150px;
  height: 45px !important;
  padding: 8px 10px !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%) !important;
  border: 1px solid #c8850c !important;
  font-size: 18px !important;
  color: #010303 !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  font-family: "Space Grotesk";
  z-index: 1;
}
.contact-info {
  text-align: right;
}
section.banner-sec {
  position: relative;
  padding: 50px 0px;
  background-image: url(../images/bg-grid.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header-top {
  position: absolute;
  width: 100%;
  display: flex;
  top: -130px;
}

.header-top img {
  max-width: 400px;
  width: 100%;
}
.sladir-slaide {
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}
.head-headding span {
  font-family: "Space Grotesk";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  background: linear-gradient(180deg, #ffffff 30%, #999999 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #d3ad6980;
  padding: 6px 12px;
  border-radius: 100px;
}
.head-headding {
  text-align: center;
}
.sladir-slaide h2 {
  font-family: "Space Grotesk";
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.04em;
  text-align: center;
  background: linear-gradient(180deg, #ffffff 30%, #999999 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 16px 0px;
  z-index: 99;
  position: relative;
}

.sladir-slaide p {
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #c5c5c5;
  z-index: 99;
  position: relative;
}
.sladir-slaide p {
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #c5c5c5;
  margin-bottom: 48px;
}

.left-coins img.coin-first02 {
  width: 80px;
  height: 67.88px;
  top: 174px;
  left: 190px;
  position: absolute;
  animation-duration: 20s;
  animation-name: rotateme;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.left-coins img.coin-first03 {
  width: 100px;
  height: 47.28px;
  top: 80px;
  left: 0;
  position: absolute;
  animation: movebounce 2s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.left-coins img.coin-first01 {
  position: absolute;
  width: 80px;
  height: 67.88px;
  top: 53px;
  left: 230px;
  animation: moveleftbounce 2s linear infinite;
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.left-coins img.coin-first04 {
  width: 107.09px;
  height: 52.73px;
  top: 244.34px;
  left: 0;
  position: absolute;
  animation: movebounce 2s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.right-coins img.coin-first02 {
  width: 80px;
  height: 67.88px;
  top: 174px;
  right: 190px;
  position: absolute;
  animation-duration: 20s;
  animation-name: rotateme;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.right-coins img.coin-first03 {
  width: 100px;
  height: 47.28px;
  top: 80px;
  right: 0;
  position: absolute;
  animation: movebounce 2s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.right-coins img.coin-first01 {
  position: absolute;
  width: 80px;
  height: 67.88px;
  top: 53px;
  right: 230px;
  animation: moveleftbounce 2s linear infinite;
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.right-coins img.coin-first04 {
  width: 107.09px;
  height: 52.73px;
  top: 244.34px;
  right: 0;
  position: absolute;
  animation: movebounce 2s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.about-img img {
  width: 100%;
  max-width: 500px;
}
.section-headding h4::after {
  position: absolute;
  content: "";
  height: 4px;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: 100%;
  bottom: 0px;
  left: 0;
}
.right-about-outer {
  padding: 24px 10px;
}
.section-headding h4 {
  position: relative;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--theme);
  width: fit-content;
  padding-bottom: 8px;
  margin: 0 auto;
}
.right-about-outer h4 {
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
  line-height: 45.94px;
  text-align: left;
  color: var(--theme);
}
.right-about-outer p {
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #c5c5c5;
}
.right-about-outer a {
  font-family: "Space Grotesk";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.feature-card {
  padding: 40px;
  border-radius: 32px;
  background-color: #f5f5dc1c;
}
.main-carousel-card-img img {
  width: 40px !important;
  height: 40px;
}
.feature-card h6 {
  font-family: "Space Grotesk";
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: var(--theme);
}
.feature-card p {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--theme);
  border-bottom: 1px solid #ffffff3d;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.feature-listed ul li {
  display: flex;
  gap: 10px;
  color: #ffffffcc;
  font-family: "Space Grotesk";
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 15px 0px;
}

.feature-listed ul li img {
  width: 16px !important;
}

.feature-listed ul {
  padding: 0;
  margin: 0;
}
.feature-item {
  padding-top: 28px;
}
section.abouts-section {
  padding: 50px 0px;
  position: relative;
}
section.abouts-section::after {
  position: absolute;
  content: "";
  left: 0px;
  background-image: url(../images/about-circal.svg);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: left;
}
section.Feature-section {
  position: relative;
  padding: 50px 0px;
}
section.Feature-section::after {
  position: absolute;
  content: "";
  right: 0px;
  background-image: url(../images/circalrightfeature.svg);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;
}
section.Feature-section::before {
  position: absolute;
  content: "";
  right: 180px;
  background-image: url(../images/coines.svg);
  width: 100%;
  height: 100%;
  top: 50px;
  bottom: 0;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: right;
}
.owl-item.active.center .feature-card {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  border: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    #ffcf78 0%,
    var(--theme1Dark) 100%
  );
}
.owl-item.active.center .feature-card p,
.owl-item.active.center .feature-card h6 {
  color: var(--theme1Dark);
}
.owl-item.active.center .feature-listed ul li {
  color: var(--theme1Dark);
}
.owl-item.active.center .feature-listed ul li img {
  filter: invert(1);
}
.feature-item .owl-carousel .owl-nav.disabled {
  display: block !important;
}
.feature-item .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: -65px;
  top: 50%;
  border: 0px solid #c8850c;
  background-color: transparent !important;
  background-image: url(../images/left-nav.svg) !important;
  background-repeat: no-repeat;
  background-size: 55px;
  width: 55px;
  content-visibility: hidden;
  height: 55px;
}

.feature-item .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: -65px;
  top: 50%;
  border: 0px solid #c8850c;
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-image: url(../images/right-nav.svg) !important;
  background-size: 55px;
  width: 55px;
  content-visibility: hidden;
  height: 55px;
}
.feature-item .owl-carousel .owl-nav [class*="owl-"]:hover {
  background-size: 55px;
  text-decoration: none;
}
.testimonial-card h6 {
  font-family: "Space Grotesk";
  font-size: 20px;
  font-weight: 600;
  line-height: 25.52px;
  padding: 45px;
  color: var(--theme);
}
.star-icon img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto 45px;
}
.test-user img {
  width: 80px !important;
  height: 80px;
}
.testimonial-listed {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
}
.testimonial-details h6 {
  font-family: "Space Grotesk";
  font-size: 19.49px;
  font-weight: 700;
  line-height: 24.87px;
  padding: 0px;
}
.testimonial-details p {
  font-family: "Space Grotesk";
  font-size: 16.24px;
  font-weight: 700;
  line-height: 20.73px;
  color: #ffffff80;
}
.testimonial-card {
  background-image: url(../images/Texture\ 1.png);
  background-repeat: no-repeat;
  background-size: cover !important;
  background-size: 100%;
  padding: 20px;
  border-radius: 15px;
}
.owl-item.active.center .testimonial-card {
  background-image: url(../images/testimonial-bg.png);
  background-position: bottom;
}
section.testimonial-section {
  margin: 100px 0px;
  position: relative;
}
.section-headding {
  margin-bottom: 30px;
}
.testimonial-item .owl-carousel .owl-dots.disabled {
  display: block !important;
}
.testimonial-item .owl-carousel .owl-dots span {
  width: 15px !important;
  height: 15px !important;
  margin: 5px 7px;
  background: #262626;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.testimonial-item .owl-carousel .owl-dots .owl-dot.active span,
.testimonial-item .owl-carousel .owl-dots .owl-dot:hover span {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
}
.contacts-box h2 {
  font-family: "Space Grotesk";
  font-size: 70px;
  font-weight: 400;
  line-height: 89.32px;
  letter-spacing: -0.04em;
  background: linear-gradient(180deg, #ffffff 30%, #999999 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contacts-box {
  display: flex;
  gap: 200px;
  margin-bottom: 40px;
}
.social-media-icon ul {
  margin: 0;
  display: grid;
  gap: 24px;
}
input.contact-input,
textarea.contact-textarea {
  padding: 10px;
  border-bottom: 1px solid #644206 !important;
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--theme);
  outline: none !important;
}
button.ant-btn.ant-btn-submit.submit-button {
  width: 100%;
  height: 45px !important;
  padding: 8px 10px !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%) !important;
  border: 1px solid #c8850c !important;
  font-size: 18px !important;
  color: #010303 !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  font-family: "Space Grotesk";
}
.contact-form-btn {
  margin-top: 50px;
}
.form-inn textarea.contact-textarea {
  margin-top: 25px;
}
section.newsletter-section {
  padding: 100px 0px;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  margin: 100px 0px;
}
.news-form-inn input {
  background: #ffffff1a;
  border: none;
  outline: none;
  padding: 24px 32px;
  border-radius: 8px 0px 0px 8px;
  width: 100%;
}
.news-form-inn {
  position: relative;
  display: flex;
}
.news-form-inn button {
  box-shadow: 0px 10px 10px 0px #1089ff1a;
  height: 70px;
  border-radius: 0px 8px 8px 0px;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  text-align: left;
  color: #010303;
}
.news-menus h4 {
  font-family: "Space Grotesk";
  font-size: 40px;
  font-weight: 700;
  line-height: 51.04px;
  text-align: left;
}
.news-menus p {
  font-family: "Space Grotesk";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
.footer-logo {
  text-align: center;
}
.icon-box {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto 15px;
}
.footer-social a {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--theme) !important;
}

.footer-social {
  text-align: center;
}
.footer-social {
  margin: 30px 0px;
}
.d-footer-text {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
}
.d-footer-text a {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--theme) !important;
}
section.ant-layout.layout-default.layout-signin {
  background: var(--theme1Dark);
  background-image: url(../images/login-top-circal.svg);
  background-repeat: no-repeat;
  background-position: top;
}
.signin-box {
  max-width: 570px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  /* background-image: url(../images//bg-grid.svg); */
  background-position: top;
  z-index: 9;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.signin-box::before {
  content: "";
  position: absolute;
  background-image: url(../images/bg-grid.svg);
  top: -150px;
  width: 100%;
  height: 100%;
  background-size: auto;
  left: 0;
  background-position: top;
}
.h-sign-menu img {
  width: 150px;
  height: 150px;
  flex: 0 0 150px;
  margin-bottom: 15px;
  position: relative;
}
.h-sign-menu h1.ant-typography.tital-text-sign {
  color: var(--theme);
  font-family: "Space Grotesk";
  font-size: 32px;
  font-weight: 700;
  line-height: 40.83px;
  text-align: center;
}
.signup-form {
  padding-bottom: 32px;
}
.ant-form-item.username-form label {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--theme);
}
.form-of-type {
  height: 56px;
  border: 1px solid #624f2a !important;
  padding: 10px !important;
  overflow: hidden;
  color: var(--theme) !important;
  background-color: #0d0d0d !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  width: 100%;
}
.ant-input-password-icon.anticon {
  color: var(--theme) !important;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 16px;
}
.h-sign-menu {
  text-align: center;
  margin: 40px auto;
  width: 100%;
}
.h-sign-menu p {
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #c5c5c5;
  width: 70%;
  margin: 0 auto;
}
.ant-form-item.remember-type-text .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  gap: 0px;
  color: var(--theme);
}
label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item span {
  color: var(--theme);
}
label.rem-check span.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border: 1px solid #3f4040 !important;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #c8850c;
}
.ant-checkbox-inner:after {
  left: 0 !important;
  right: 0;
  margin: 0 auto;
}
.ant-checkbox-checked:after {
  border: 0px solid !important;
}
.forgot-pass {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}
span.ac-create a {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 600;
  line-height: 17.86px;
  letter-spacing: normal;
  text-decoration: underline;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
}
span.ac-create {
  border-bottom: 1px solid #c8850c;
}
.form-of-type input {
  color: #fff !important;
  background-color: var(--theme1Dark) !important;
}
.signin.container .header {
  background-color: transparent;
}
.ant-form-item.signin-footer {
  margin-top: 20px;
}
.signup-logo button.ant-btn.ant-btn-button.prev-pagebtn {
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--theme);
  border: none;
}
.ant-input,
.ant-input-affix-wrapper,
.ant-input:focus,
.ant-input:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper input {
  background-color: black !important;
  color: var(--theme) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px black inset !important;
  -webkit-text-fill-color: var(--theme) !important;
}
span.ant-input-affix-wrapper.ant-input-affix-wrapper-focused.ant-input-password.form-of-type.ant-input-affix-wrapper-status-success
  > .ant-input:focus {
  -webkit-box-shadow: 0 0 0px 1000px black inset !important;
  -webkit-text-fill-color: var(--theme) !important;
}
.ant-form-item.otp-form .ant-form-item-control-input-content {
  display: flex;
  gap: 20px;
}
input.ant-input.input-otp__field.form-of-type {
  max-width: 65px;
  width: 100%;
}
.input-otp {
  display: flex;
  gap: 1em;
  justify-content: center;
  width: 100%;
}
.otp-timing {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 600;
  line-height: 17.86px;
  text-align: center;
  color: #a19d9d;
}
.otp-timing span {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.user-dirct {
  text-align: center;
  color: var(--theme);
}
.ant-form-item.otp-form
  .ant-form-item-control-input-content
  input.ant-input.form-of-type {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
}
.h-sign-menu p a {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.walletbox-card.golden-card {
  background-image: url(../images/wlt-gold-bg.svg);
}
.walletbox-card {
  background-image: url(../images/wlt-dark-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px;
  min-height: 160px;
  height: 100%;
  border-radius: 20px;
}
.wlt-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wlt-card-body h6 {
  font-family: "Space Grotesk";
  font-size: 25px;
  font-weight: 400;
  line-height: 31.9px;
  color: var(--theme);
}
.wlt-card-body span {
  font-family: "Space Grotesk";
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  background-color: var(--theme1Dark);
  color: var(--theme);
  padding: 5px 12px;
  border-radius: 20px;
}
.wlt-price {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  margin: 0;
}
.wlt-price h4 {
  font-size: 40px;
  font-weight: 800;
  line-height: 60px;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.walletbox-card.golden-card .wlt-price h4 {
  color: var(--theme1Dark) !important;
  background: none;
  -webkit-text-fill-color: var(--theme1Dark);
}
.ant-card.ant-card-bordered.home-card {
  background: #151718;
  border: 1px solid #151718;
  border-radius: 20px;
  overflow: hidden;
}
.graph-items img {
  width: 100%;
}
.ant-card.ant-card-bordered.home-card .ant-card-body {
  padding: 10px 10px !important;
  background: #151718;
}
.walletbox-card.golden-card .wlt-card-body h6 {
  color: var(--theme1Dark);
}
.qnty-box h6 {
  font-family: "Space Grotesk";
  font-size: 22px;
  font-weight: 700;
  line-height: 28.07px;
  color: var(--theme);
  padding-right: 20px;
}
.qnty-price {
  border: 1px solid #332121;
  background-color: #332121;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 600;
  line-height: 24.42px;
  letter-spacing: -0.5px;
  min-height: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #27ae60;
  margin: 32px auto; 
  width: fit-content;
  padding: 10px 20px;
}
li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child span {
  color: #000;
}
.up-dawn-errow {
  position: absolute;
  right: 15px;
  top: 15px;
  color: rgb(120, 247, 82);
  font-size: 20px;
}
.homex-outerr {
  display: flex;
  gap: 20px;
  align-items: center;
}
.homex-outerr .qnty-price {
  width: 100%;
}
.homex-outerr button.ant-btn.ant-btn-default.price-buy {
  max-width: 170px;
}
.quantity-pricebox .ant-select.price-select .ant-select-selector{
  min-width: 105px;
}
button.ant-btn.ant-btn-default.price-buy {
  background: #FFA500;
  width: 100%;
  height: 50px;
  color: var(--theme1Dark);
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  max-width: 175px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

input.ant-input.quantity-inputs {
  border: 1px solid #333333;
  background-color: var(--theme1Dark);
  padding: 16px;
  min-height: 56px;
  height: 100%;
  border-radius: 10px;
  box-shadow: none !important;
}
.ant-select.price-select .ant-select-selector {
  position: relative;
  border: 1px solid #333333 !important;
  background-color: var(--theme1Dark) !important;
  padding: 10px !important;
  min-height: 56px !important;
  height: 100%;
  border-radius: 10px !important;
  color: #fff;
  text-align: left;
  box-shadow: none !important;
}
.ant-select.price-select .ant-select-arrow {
  color: #fff !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #3f4040 !important;
  border-right-width: 1px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #3f4040 !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: 0 !important;
  text-align: left;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  background-color: #000 !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #000000 !important;
  color: #fff !important;
}
.ant-select-item {
  color: rgb(255 255 255) !important;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgb(255 255 255) !important;
  font-weight: 600;
  background-color: #151718 !important ;
}
.quantity-pricebox {
  display: flex;
  gap: 20px;
  margin: 16px 0px 2px 0px;
}
.ant-table-wrapper.main-table .ant-table-thead > tr > th {
  position: relative;
  color: rgb(0 0 0);
  background: #daa520;
  border-bottom: 0.95px solid #212325;
  transition: background 0.3s ease;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 600;
  line-height: 17.16px;
  padding: 8px 15px;
}
.ant-table-wrapper.main-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #212325;
  transition: background 0.3s;
  background-color: #1a1d1e;
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 13px;
  font-weight: 400;
  padding: 8px 15px;
  line-height: 17.16px;
}
.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
  white-space: nowrap;
}
.title-left h4 {
  font-family: "Space Grotesk";
  font-size: 24.79px;
  font-weight: 400;
  line-height: 38.14px;
  margin: 0;
  padding: 0;
  color: #fff;
}
.ant-card-head {
  padding: 0;
  background: #151718;
}
.ant-card-head-title,
.ant-card-head-wrapper {
  background: #151718;
  padding: 0px !important;
}
.ant-card.ant-card-bordered.table-card .ant-card-body {
  padding: 10px 10px !important;
  background: #151718;
}
.ant-card.ant-card-bordered.table-card {
  background: #151718;
  border: 1px solid #151718;
  border-radius: 20px;
  overflow: hidden;
}
.ant-card.ant-card-bordered.table-card .ant-card-head {
  padding: 0;
  background: #151718;
}
.ant-card.cap.criclebox.tablespace .ant-card-body {
  padding: 0px !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #000 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border: 4px solid transparent;
  border-radius: 25px;
  background: linear-gradient(to right, white, white),
    linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.ant-modal.tab_modal .ant-modal-content {
  border-radius: 30px;
  box-shadow: 0 0 10px #00000021;
  overflow: hidden;
  background: #0d0d0d !important;
  background: linear-gradient(to right, #0d0d0d, #0d0d0d),
    linear-gradient(180deg, #ffcf78 0%, #c8850c 100%) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
  border: 1px solid !important;
}
.ant-modal.tab_modal .ant-modal-header {
  background: #0d0d0d !important;
  border-bottom: 1px solid #212121;
}
.ant-modal.tab_modal .ant-modal-title {
  margin: 0;
  color: rgb(255 255 255 / 85%);
  font-family: "Space Grotesk";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}
.ant-modal-close-x {
  display: block;
  width: 40px !important;
  height: 40px !important;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  border-radius: 50%;
  background: #212121;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.ant-modal-close {
  position: absolute;
  top: 10px !important;
  right: 15px !important;
}
.price-n-text h3 {
  font-family: "Space Grotesk";
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}
.buy-listing {
  background-color: #151515;
  border-radius: 20px;
  padding: 20px;
}
.buy-listing ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.buy-listing ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #212121;
  padding-bottom: 10px;
}
.buy-listing ul li span {
  font-family: "Space Grotesk";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #929292;
}
.buy-listing ul li p {
  font-family: "Space Grotesk";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #e9e9e9;
  margin-bottom: 0px;
}

#button-background {
  position: relative;
  background-color: #3a3941;
  max-width: 335px;
  width: 100%;
  margin: 20px auto 10px;
  height: 54px;
  border: none;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#button-background.bg-converted {
  background: white;
}

#slider {
  transition: width 0.3s, border-radius 0.3s, height 0.3s;
  position: absolute;
  left: 4px;
  background-color: #19ff52;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* 
#slider.unlocked {
	transition: all 0.3s;
	left: calc(50% - 26px) !important;
	height: 54px;
	border-radius: 40px;
	animation-name: spin;
	animation-duration: 500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.loading {
	display: none;
}

.material-icons {
	color: black;
	font-size: 50px;
	user-select: none;
}

.md-arrow {
	width: 15px;
	font-size: 18px;
	font-weight: bold;
	color: #000 !important;
	display: flex;
	justify-content: center;
}

.slide-text {
	color: #ffffff;
	font-family: 'Space Grotesk';
  font-size: 18px;
  font-weight: 700;
  line-height: 20px; 

	user-select: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

#slider.unlocked {
  transition: all 0.3s;
  width: inherit;
  left: 0 !important;
  height: inherit;
  border-radius: inherit;
}
#button-background {
  position: relative;
  max-width: 360px;
  width: 100%;
  height: 70px;
  border: 2px solid #3a3941;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a3941;
}
.slide-text {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  user-select: none;
  cursor: default;
  margin-left: 11%;
}
#slider {
  transition: width 0.3s, border-radius 0.3s, height 0.3s;
  position: absolute;
  top: 5px;
  left: 3px;
  background-color: #19ff52;
  border: 2px solid #19ff52;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recived-modal {
  padding-top: 70px;
  text-align: center;
}

.recived-modal img {
  width: 50px;
  height: 50px;
}

.recived-modal h3 {
  font-family: "Space Grotesk";
  font-size: 32px;
  font-weight: 700;
  line-height: 40.83px;
  text-align: center;
  color: #fff;
  margin: 20px 0px;
}

.recived-modal p {
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #929292;
}
button.ant-btn.ant-btn-default.btn-outers {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: 100%;
  height: 50px;
  color: var(--theme1Dark);
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  max-width: 200px;
}
button.ant-btn.ant-btn-default.wlt-btn {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: fit-content;
  height: 50px;
  color: var(--theme1Dark);
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 5px;
}

.wallet-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.wallet-number {
  display: flex;
  align-items: center;
  gap: 20px;
}

.wlts-contant p {
  font-family: "Space Grotesk";
  font-size: 12px;
  font-weight: 400;
  line-height: 20.31px;
  margin: 0;
  color: #fff;
}

.wlts-contant h4 {
  font-family: "Space Grotesk";
  font-size: 22px;
  font-weight: 700;
  line-height: 30.62px;
  text-align: left;
  color: #fff;
  margin: 0;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme1Dark) !important;
  text-shadow: none;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: 175px;
  height: 40px;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tabs-tab-btn {
  color: #fff !important;
  text-shadow: none;
  background: #0d0d0d;
  width: 175px;
  height: 40px;
  border: 1px solid #262626;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tabs-tab {
  padding-top: 0px;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}
.ant-tabs-bottom > .ant-tabs-nav:before,
.ant-tabs-bottom > div > .ant-tabs-nav:before,
.ant-tabs-top > .ant-tabs-nav:before,
.ant-tabs-top > div > .ant-tabs-nav:before {
  display: none;
}
.ant-tabs.ant-tabs-top.main_tabs .ant-tabs-nav {
  margin: 0px;
}
.ant-card.cap.criclebox.tablespace.mb-24 .ant-card-head-wrapper {
  background: #151718;
  padding: 0px !important;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 15px;
}
.ant-card-extra {
  padding: 0px !important;
}
.pageHeadingSearch .searchInput .ant-input {
  border-radius: 50px !important;
  border: solid 1px #1d2021 !important;
  padding: 12px 20px !important;
  font-size: 14px !important;
  height: 45px !important;
  min-width: 100%;
  background-color: #1d2021 !important;
}
.pageHeadingSearch .ant-input-affix-wrapper:has(> .ant-input) {
  border-radius: 60px !important;
  background: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
  padding: 0px;
  border: 0;
}
.ant-input-wrapper .ant-input-group-addon {
  left: 0 !important;
  background-color: transparent;
}
.ant-input-wrapper .ant-input-group-addon .ant-btn-default {
  border-radius: 0 7px 7px 0 !important;
  width: 42px;
  height: 42px !important;
}
.pageHeadingSearch button.ant-btn.ant-btn-default {
  position: absolute;
  right: 0 !important;
  top: 0;
}
.ant-btn .anticon svg {
  margin-right: 0;
}
.ant-input-search-button svg path {
  fill: var(--theme);
}
.ant-input-search-button {
  width: 42px !important;
  height: 42px !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  border-radius: 50px !important;
  z-index: 1;
}
.profile-container {
  display: flex;
  justify-content: center;
  /* align-items: center;
  height: 100vh; */
  margin-bottom: 20px;
  background-color: #000;
}

.profile-card {
  max-width: 600px;
  width: 100%;
  text-align: center;
  background-color: #1d1d1d;
  border-radius: 10px;
  border: 1px solid #2a2a2a;
}

.profile-name {
  color: white !important;
}

.profile-email {
  color: #ccc !important;
}
.profile-details {
  margin-bottom: 50px;
  color: white !important;
  padding: 0;
  list-style: none;
}
.ant-card.ant-card-bordered.profile-card .ant-card-body {
  padding: 30px 60px !important;
}
.edit-icon {
  position: absolute;
  top: 95px;
  right: calc(50% - 55px);
}
.edit-icon button.ant-btn.ant-btn-circle.ant-btn-default.ant-btn-icon-only {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  border: 1px solid #000;
}

button.ant-btn.ant-btn-primary.edit-profile-button {
  color: var(--theme1Dark) !important;
  text-shadow: none;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: 100%;
  height: 50px;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
ul.profile-details li span {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  color: #c5c5c5;
}
ul.profile-details li p {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 500;
  line-height: 17.86px;
  margin: 0;
}
ul.profile-details li {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
  gap: 100px;
}
.ant-form-item.select-form .ant-row.ant-form-item-row {
  display: grid;
}
.ant-form-item.select-form .ant-col.ant-form-item-label {
  text-align: left;
}
.ant-form-item.select-form .ant-col.ant-form-item-label label {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--theme);
}
.headdeng-forms h4 {
  font-family: "Space Grotesk";
  font-size: 25px;
  font-weight: 700;
  line-height: 22.05px;
  letter-spacing: -0.26461538672447205px;
  text-align: left;
  color: #fff;
}
.help-text h6 {
  font-family: "Space Grotesk";
  font-size: 28px;
  font-weight: 700;
  line-height: 35.73px;
  text-align: left;
  color: #fff;
}
.help-text p {
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #929292;
}
.ant-card.ant-card-bordered.home-card.help-card .ant-card-body {
  padding: 20px 30px !important;
}

/* Slider Confirmation Style */

.slider-container {
  position: relative;
  height: 50px;
  width: 50%;
  margin-top: 20px;
  margin: auto;
  background-color: #444; /* Default background color */
  border-radius: 50px;
  overflow: hidden; /* Ensures the slider background doesn't overflow */
}

.slider {
  width: 100%;
  appearance: none;
  height: 50px;
  background: transparent; /* Make the default slider background transparent */
  outline: none;
  opacity: 0; /* Hide the default range slider */
}

.slider-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #32cd32;
  border-radius: 50px;
  z-index: 1; /* Ensure the background is below the button */
  transition: width 0.3s ease-in-out;
}

.slider-button {
  position: absolute;
  top: 0; 
  height: 50px;
  width: 50px; /* Adjust button width */
  background-color: #32cd32;
  border-radius: 50px;
  z-index: 2; /* Ensure the button is above the background */ 
  transition: left 0.3s ease-in-out;
  cursor: pointer; /* Allow the button to be draggable */
}

.slider-button.confirmed {
  background-color: #444;
  left: calc(100% - 25px); /* Adjust for centered position */
}

.slider-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 3; /* Ensure the text is on top of everything */
  pointer-events: none; /* Prevent interaction with the text */
}
.ant-tag { 
  font-size: 11px; 
  border-radius: 15px !important; 
}
.ant-pagination-item { 
  background-color: #000000 !important;
  border: 1px solid #9E9E9E !important;
  border-radius: 2px;
  color: #fff !important; 
}
.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link { 
  color: #fff !important; 
  background-color: #000000 !important;
  border: 1px solid #9E9E9E !important; 
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgb(255 255 255 / 96%);
  border-color: #9E9E9E;
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #000000 !important;
  border: 1px solid #9E9E9E !important; 
  color: #fff !important; 
}
.ant-select-arrow { 
  color: rgb(255 255 255 / 98%); 
}
.ant-select-dropdown {
  background-color: #000 !important;
}
.ant-pagination-item-active{
  background-color: #c9870f !important;
  border-color: #c9870f !important;
}
.react-tel-input.form-of-phone input.form-control {
  height: 56px !important;
  border: 1px solid #624f2a !important;
  /* padding: 10px !important; */
  color: var(--theme) !important;
  background-color: #0d0d0d !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  width: 100% !important;
}
.react-tel-input.form-of-phone .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #0d0d0d;
  border: 1px solid #624f2a;
  border-radius: 10px 0 0 10px !important;
}
.react-tel-input.form-of-phone .flag-dropdown.open .selected-flag,.react-tel-input.form-of-phone .flag-dropdown.open {
  background: #000000 !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input.form-of-phone .country-list .country {
  padding: 7px 9px;
  color: #fff;
  text-align: left;
}
.react-tel-input.form-of-phone .country-list { 
  background-color: #000000;
  width: 230px; 
  scrollbar-width: none;
  scrollbar-width: 0px !important; 
}
.react-tel-input.form-of-phone .country-list .country:hover {
  background-color: #2e2e2e;
}
.date-innn .ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgb(255 255 255 / 63%);
  line-height: 1;
  font-size: 20px;
  pointer-events: none;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before { 
  border: 1px solid #cf8f1a !important; 
}
td.ant-picker-cell.ant-picker-cell-in-view {
  color: rgb(255 255 255 / 85%) !important;
}
.ant-picker-content th { 
  color: rgb(255 255 255 / 85%) !important; 
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #505050 !important;
}
.ant-picker-header button { 
  color: rgb(255 255 255) !important; 
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #cf8f1a !important;
}
.ant-picker-panel-container { 
  background: #000000 !important; 
}
.ant-picker-today-btn {
  color: #ffffff !important;
}
.ant-picker-panel { 
  background: #000 !important;
  border: 1px solid #505050 !important; 
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #fff;
  background: #cf8f1a !important;
}
.ant-picker-cell { 
  color: rgb(255 255 255 / 39%) !important; 
}
.ant-picker-header { 
  border-bottom: 1px solid #505050 !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #282828 !important;
}
.ant-progress-default.lavel-bar .ant-progress-bg,.ant-progress-default.lavel-bar .ant-progress-success-bg {
  height: 22px !important;
}
span.ant-progress-text {
  display: none;
}
.ant-modal.tab_modal .ant-modal-footer {
  border: 0;
  padding: 25px 0 20px;
  text-align: center;
}
.ant-modal.tab_modal .ant-modal-footer button.ant-btn.ant-btn-default {
  background: #fff !important;
  border: 1px solid #000000!important; 
  letter-spacing: .08em!important;
  text-transform: uppercase!important;
}
.ant-modal.tab_modal .ant-modal-footer .ant-btn { 
  border-radius: 10px!important;
  box-shadow: 0 9px 36px 0 #7a81be29;
  color: #000000!important; 
  font-family: "Space Grotesk";
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23px;
  min-height: 52px;
  min-width: 130px;
  padding: 13px 30px!important;
  text-align: left;
}
.ant-modal.tab_modal .ant-modal-footer .ant-btn.ant-btn-primary {
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  border-color: #c8850c!important;
  letter-spacing: .08em!important;
  text-transform: uppercase!important;
}

.center-xy {
  display: flex;
  justify-content: center;
  align-items: center;  
  margin-top: 10px;
}

.swipe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #323232; /* Background for slider */
  border-radius: 50px;
  padding: 0px;
  width: 320px;
  height: 60px;
}
.pullee {
  -webkit-appearance: none;
  appearance: none;
  width: 300px;
  height: 60px;
  background: transparent;
  outline: none;
}

.pullee::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 60px;
  height: 60px;
  background: #00cc44; /* Green circle */
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
  position: relative;
  background-image: url(../images/swipe-right.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.pullee::-moz-range-thumb {
  width: 60px;
  height: 60px;
  background: #00cc44;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
  background-image: url(../images/swipe-right.svg);
  background-position: center;
  background-repeat: no-repeat;
}


.swipe-container p {
  color: white;
  font-size: 18px;
  margin-left: 15px;
  font-family: Arial, sans-serif;
  position: absolute;
  margin-bottom: 0;
  left: 90px;
}
.count-box h1 {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #323232;
  width: 100%;
  border-radius: 11px;
  max-width: 100px;
  height: 50px;
  font-weight: 600;
  font-size: 24px;
  margin: 0 auto 10px auto;
}
.count-box h1 span{
  margin-right: 10px;
}
.count-box-p{
  text-align: center;
  color: lightgreen;
  font-size: large;
}
.wlt-header-inn {
  display: flex;
  gap: 10px;
}
.wlt-header {
  display: flex;
  gap: 10px;
  align-items: center;
}
.walletbox-card p {
  color: white;
  font-size: 22px;
  padding: 0px;
  margin: 0;
}
.walletbox-card h4 {
  color: goldenrod;
  font-size: 26px;
  font-weight: 700;
  margin:10px 0px 0px;
}
.ant-progress.lavel-bar {
  margin-top: 15px;
}
.edit-footer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.upload-img .ant-upload.ant-upload-select-picture-card {
  position: relative;
  background-color: #000000;
  border: 2px solid #c9860d;
  border-radius: 50%; 
}
.upload-img .remove-wrap {
  position: absolute;
  top: 95px;
  right: calc(50% - 55px);
  background-color: black;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 9;
  align-items: center;
}
.upload-img .ant-upload.ant-upload-select-picture-card .ant-image{
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: top;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px !important;
}
.upload-img .ant-upload span .ant-image img{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #c9860d !important;
}
.upload-img .ant-upload .user-upload-ic {
  display: none;
}
.ant-picker-clear { 
  right: 26px !important; 
  color: rgb(255 255 255) !important;
  background: #000000 !important; 
}
.ant-card-head-title {
  display: block!important;
  flex: 0 0 auto!important;
}
.ant-card-extra {
  display: flex;
  justify-content: space-between;
 
  margin-left: auto;
  gap: 5px !important;
}
.testimonial-item .owl-nav {
  display: none;
}
.confirmed-section span {
  color: #00cc44;
  font-size: 55px;
  margin-bottom: 20px;
}
.confirmed-section h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40.83px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.confirmed-section p {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #929292;
}
button.ant-btn.ant-btn-default.cnf-button {
  color: var(--theme1Dark) !important;
  text-shadow: none;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
  width: 100%;
  height: 50px;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 175px;
  margin: 0 auto;
}
button.ant-btn.ant-btn-default.cnf-button span{
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  color:var(--theme1Dark) !important;
}
button.ant-btn.ant-btn-default.upload-btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -9px;
  z-index: 1;
  border-color: #442b16;
  border-radius: 50%;
  bottom: 6px;
  background: linear-gradient(180deg, #ffcf78 0%, #c8850c 100%);
}
td.ant-table-cell.buy-column , th.ant-table-cell.buy-column {
  text-align: center;
  color: #64a914;
  border-left: 1px solid #00000033;
  border-right: 1px solid #00000033;
}
td.ant-table-cell.sell-column , th.ant-table-cell.sell-column {
  text-align: center;
  color: #e6831b;
}
.ant-table-wrapper.currency-table {
  border: 1px solid #272727;
}
td.ant-table-cell.buy-column {
  text-align: center;
  border-left: 1px solid #212325;
  border-right: 1px solid #212325;
}
.ant-table-wrapper.currency-table .ant-table-tbody>tr>td {
  border-bottom: 1px solid #212325 !important; 
  background: #151718;
  padding: 10px 16px !important;
  color: #fff;
}
.ant-table-thead>tr>th { 
  position: relative;
  font-weight: 700 !important;
  color: rgb(0 0 0) !important;
  background: #ff9800 !important;
  border-bottom: 0.95px solid #212325  !important; 
}
.ant-table-wrapper.currency-table {
  border: 1px solid #272727;
  background: #151718;
}
.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
  color: #fff !important;
  background-color: #000000 !important;
  border: 1px solid #9E9E9E !important;
}
li.ant-pagination-item.ant-pagination-item-active {
  background-color: #c9870f !important;
  border-color: #c9870f !important;
}
.ant-pagination-item {
  background-color: #000000 !important;
  border: 1px solid #9E9E9E !important;
  border-radius: 2px;
  color: #fff !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector { 
  background-color: #000000 !important;
  border: 1px solid #d9d9d9 !important; 
  color: #ffffff;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 16px !important;
}
.ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #000000 !important;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden !important;
} 
.ant-pagination-jump-next, .ant-pagination-jump-prev {
  display: none !important;
}
.sladir-slaide.rate-tabl h2, .sladir-slaide.rate-tabl p{
  text-align: left;
}
section.rate-section {
  padding: 50px 0px;
  position: relative;
}
path.highcharts-grid-line {
  stroke: #333333 !important;
}
g.highcharts-range-selector-group {
  display: none  !important;
}
.main-new-scanner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.main-new-scanner-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  color: black !important;
  text-align: center;
}
.main-new-scanner-card h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}
.main-new-scanner-card p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.main-new-scanner-card h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.ant-modal.main-new-scanner-qrcode .ant-modal-content {
  border-radius: 15px !important;
  overflow: hidden;
}
.ant-modal.main-new-scanner-qrcode .ant-modal-content .ant-modal-title {
  font-size: 17px;
  font-weight: 700;
}
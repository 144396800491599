 @media (min-width: 576px) {
    
}
@media (min-width: 768px) {
  
  
}
@media (min-width: 992px) {
    .layout-dashboard .ant-menu { 
        padding: 0px 25px;
    }
    .layout-dashboard .ant-layout-header {
        margin: 0px;
        padding: 10px 15px 10px 15px;
    }
    main.ant-layout-content.content-ant {
        padding: 0 28px;
    }
} 
@media (min-width:1569.98px) {
  
}
@media (max-width:1569.98px) {
  
}
@media (max-width:1199.98px){
    .walletbox-card h4 { 
        font-size: 20px; 
    }
    .contacts-box { 
        gap: 0; 
        flex-wrap: wrap;
    }
    .social-media-icon ul { 
        display: flex;
        gap: 24px;
        padding: 0;
    }
    .testimonial-card h6 { 
        font-weight: 600; 
        padding: 15px; 
    }


  
}
@media (max-width: 991.98px) {
    .layout-dashboard .ant-layout-header { 
        padding: 15px;
        margin: 0px 4px;  
    }
    .left-coins , .right-coins{
        display: none;
    }
    .drawer-sidebar .ant-drawer-body aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
        border-radius: 0;
    }
    .drawer-sidebar .ant-drawer-body section.ant-layout.ant-layout-has-sider.layout-dashboard {
        background: #383b42!important;
        border-radius: 0!important;
    }
    .drawer-sidebar .ant-drawer-body {
        background: #383b42;
        padding: 10px;
    } 
  
}
@media (max-width: 767.98px) {
    .walletbox-card { 
        padding: 15px;
        min-height: 120px; 
        border-radius: 10px;
    }
  .contact-info {
    text-align: center;
    margin-top: 10px;
}

.nav-link {
    font-size: 14px;
    padding: 5px;
}

.search-bar {
    margin-top: 10px;
}

.headericons {
    justify-content: center;
    margin-top: 10px;
}
.tabLogo img {
    width: 130px;
}
.title-left h4 {
    font-size: 18px;
    line-height: 24.14px;
}
.ant-card.ant-card-bordered.profile-card .ant-card-body {
    padding: 20px 15px !important;
}
.headdeng-forms h4 { 
    font-size: 20px;
    font-weight: 600;
    line-height: 22.05px; 
}
.ant-form-item.username-form label { 
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; 
}
.ant-card.ant-card-bordered.home-card.help-card .ant-card-body {
    padding: 20px 15px !important;
}
.help-text h6 { 
    font-size: 20px; 
    line-height: 24.73px; 
}
.help-text p { 
    font-size: 12px; 
    line-height: 18px; 
}
}
@media (max-width:575.98px){
    section.abouts-section,section.banner-sec {
        padding: 30px 0px; 
    }
    .d-footer-text { 
        flex-wrap: wrap;
        gap: 9px;
    }
    .d-footer-text a { 
        text-wrap: nowrap;
    }
    .footer-social {
        margin: 15px 0px;
        text-align: left;
    }
    .icon-box { 
        margin: 0 15px;
    }
    .news-menus p { 
        font-size: 16px; 
        line-height: 22px; 
    }
    .news-menus h4 { 
        font-size: 28px; 
        line-height: 33.04px; 
    }
    .testimonial-card h6 { 
        font-size: 18px; 
        line-height: 25.52px; 
    }
    .news-form-inn input { 
        padding: 10px 10px; 
    }
    .news-form-inn {
        margin-top: 30px;
    }
    .news-form-inn button { 
        height: 45px; 
        font-size: 13px;
        font-weight: 500;
        line-height: 20.42px; 
    }
    section.testimonial-section {
        margin: 30px 0px; 
    }
    section.Feature-section { 
        padding: 30px 0px;
    }
    .contacts-box h2 { 
        font-size: 40px; 
        line-height: 49.32px; 
    }
    section.newsletter-section{
        padding: 30px 0px;
        margin: 30px 0px;
    }
    .sladir-slaide h2 { 
        font-size: 41px; 
        line-height: 55px; 
    }
    .walletbox-card p { 
        font-size: 18px; 
    }
    .ant-tabs-tab-btn,.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { 
        width: 145px; 
    }
    .contact-form-btn {
        margin-top: 50px;
        margin: 20px auto 0px;
    }
    .wlts-contant h4 { 
        font-size: 16px; 
        line-height: 24.62px; 
    }
    .quantity-pricebox .ant-select.price-select .ant-select-selector {
        min-width: 150px;
    }
 
}
@media (max-width: 480px){
    .slide-text {
        font-size: 11px;
      }
}


